import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import netflixLogo from '../styles/logos/netflix.png';
import chatgptplusLogo from '../styles/logos/chatgptplus.png';
import midjourneyLogo from '../styles/logos/Midjourney.png';
import geminiLogo from '../styles/logos/gemini.png';
import spotifyLogo from '../styles/logos/Spotify.png';
import disneyLogo from '../styles/logos/disneyplus.png';
import claudeLogo from '../styles/logos/claude.png';
import youtubeLogo from '../styles/logos/Youtube.png';
import canvaLogo from '../styles/logos/canva.png';
import perplexityaiLogo from '../styles/logos/perplexityai.png';
import '../styles/Home.css'; // Import the CSS file for the styles

const products = [
  { id: 1, name: 'Netflix', price: { monthly: 4.5, quarterly: 25.25, yearly: 75.99 }, features: ['Globally applicable','No ads', '4K HD, Dolby','Independent account','Premium'], logo: netflixLogo },
  { id: 2, name: 'ChatGPT Plus', price: { monthly: 10.30, yearly: 110 }, features: ['greater message limit for users','Get answers', 'Be more productive', 'Find inspiration', 'GPT-4o'], logo: chatgptplusLogo },
  { id: 3, name: 'Midjourney', price: { monthly: 16.5, yearly: 168 }, features: ['No usage limit', 'Requires more time to setup','Standard Plan','annually','monthly'], logo: midjourneyLogo },
  { id: 4, name: 'Gemini', price: { monthly: 10, yearly: 99 }, features: ['Efficient learning','Get help with writing', ' brainstorming',' best choice for multimedia content'], logo: geminiLogo },
  { id: 5, name: 'Spotify', price: { monthly: 6, yearly: 60 }, features: ['Premium account', 'High-quality audio', 'Universal plan', 'Listen to podcasts'], logo: spotifyLogo },
  { id: 6, name: 'Disney+', price: { monthly: 7, yearly: 70 }, features: ['Individual sub-account', 'Supports all languages', 'Full Chinese movies and TV shows'], logo: disneyLogo },
  { id: 7, name: 'Claude', price: { monthly: 12, yearly: 120 }, features: ['Claude 3.5 Sonnet', 'Most capable LLM', ' graduate-level reasoning (GPQA)','coding proficiency'], logo: claudeLogo },
  { id: 8, name: 'YouTube', price: {yearly:40, familyYearly: 120, individualYearly: 40, familyTwoYears: 210 }, features: ['Video editing', 'For studios', 'No geographical restriction', 'Supports multilingual subtitles'], logo: youtubeLogo },
  { id: 9, name: 'Canva', price: { monthly: 8, yearly: 80 }, features: ['All regions', 'Better Stock Photos', 'More Templates: 610,000+ more design templates'], logo: canvaLogo },
  { id: 10, name: 'Perplexity AI', price: { monthly: 11, yearly: 99 }, features: ['Perplexity Pro', ' most powerful way to search the internet', 'API credits'], logo: perplexityaiLogo }
];

const Home = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="grid-container">
          {products.map(product => (
            <div key={product.id} className="grid-item">
              <img src={product.logo} alt={`${product.name} logo`} className="product-logo" />
              <h3>{product.name}</h3>
              <p>Price: {product.price.monthly ? `$${product.price.monthly}/month` : ''} {product.price.yearly ? `$${product.price.yearly}/year` : ''}</p>
              <ul>
                {product.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <Link to={`/product-details/${product.id}`} state={{ product }}>
                <button>Buy</button>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
