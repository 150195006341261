import React from 'react';
import DiscordIcon from '../styles/logos/discord.png'; // Import Discord logo
import logo from '../styles/logos/ShareAI.svg'; // Import ShareAI logo
import '../styles/Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="ShareAI" className="footer-logo" />
          <p className="footer-description">Share AI tools with friends</p>
        </div>
        <div className="footer-middle">
          <div className="footer-column">
            <h2 className="footer-heading">Community</h2>
            <button className="footer-link" onClick={() => window.location.href = 'https://discord.gg/pJjkBRPpCX'}>Join Discord</button>
            <button className="footer-link" onClick={() => window.location.href = 'mailto:imai@shareai.ai'}>Contact Us</button>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-social-icons">
            <a href="https://discord.gg/pJjkBRPpCX" target="_blank" rel="noopener noreferrer">
              <img src={DiscordIcon} alt="Discord" className="discord-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
