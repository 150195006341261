import { auth } from './firebase';
import {
  signInWithPopup,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithCredential,
  PhoneAuthProvider,
  signOut
} from 'firebase/auth';

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  return result.user;
};

export const sendVerificationCode = async (phoneNumber) => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved - will proceed with submit
      },
    }, auth);
  }

  const appVerifier = window.recaptchaVerifier;
  try {
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    return confirmationResult.verificationId;
  } catch (error) {
    console.error("Error during sending verification code: ", error);
    throw error;
  }
};

export const verifyCodeAndSignIn = async (verificationId, verificationCode) => {
  const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
  const userCredential = await signInWithCredential(auth, credential);
  return userCredential.user;
};

export const doSignOut = async () => {
  return signOut(auth);
};
