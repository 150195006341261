import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../styles/logos/ShareAI.svg';
import discordLogo from '../styles/logos/discord.png'; // Import Discord logo
import { useAuth } from '../contexts/authContext/authProvider';
import { doSignOut } from '../firebase/auth';
import '../styles/Header.css'; // Import the CSS file

const Header = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();

  const handleLogout = async () => {
    await doSignOut();
    navigate('/');
  };

  return (
    <header>
      <div className="container">
        <div className="logo">
          <Link to="/home">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <nav>
          <Link to="/home" className="nav-link">Home</Link>
          <Link to="/my-orders" className="nav-link">My Orders</Link>
          <Link to="/about" className="nav-link">About</Link>

        </nav>
        <div className="auth-buttons">
          <a href="https://discord.gg/pJjkBRPpCX" target="_blank" rel="noopener noreferrer">
            <img src={discordLogo} alt="Discord" className="discord-logo" />
          </a>
          {userLoggedIn ? (
            <button onClick={handleLogout} className="logout-button">Logout</button>
          ) : (
            <Link to="/login" className="login-button">Login/Sign Up</Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
