import React from 'react';
import Header from '../components/Header';
import '../styles/About.css';

const About = () => {
  return (
    <div>
      <Header />
      <div className="about-container">
        <div className="about-header">
          <h1>About ShareAI</h1>
          <p>Make AI Accessible to all Humanity</p>
        </div>
        <div className="about-content">
          <p>
            Hey everyone, welcome to ShareAI! As the name suggests, we share AI tools. Not everyone can afford to pay $20 every month for AI services, which is literally the minimum wage for some countries. My passion is to ensure that artificial intelligence benefits all of humanity, especially those who can't afford it.
          </p>
          <p>
            This is a project I've been postponing for a long time, and I'm just happy that I finally did it. The site is still an MVP, and I hope to improve it over time to build the safest and most reliable sharing platform.
          </p>
          <p>
            Over time, this website will exclusively feature AI-related tools. Again, welcome! Join the Discord community or email us for any feedback. Thanks for being part of this journey!:D
          </p>
        </div>
        <div className="about-footer">
          <a href="https://discord.gg/pJjkBRPpCX" target="_blank" rel="noopener noreferrer" className="btn-discord">Join our Discord</a>
          <a href="mailto:imai@shareai.ai" className="btn-email">Email Us</a>
        </div>
      </div>
    </div>
  );
};

export default About;
