import React, { useEffect, useState } from 'react';
import { getOrders } from '../services/orderService';
import Header from '../components/Header'; // Adjust the import path as needed
import '../styles/Tickets.css'; // Create and import the CSS file for styling

const Tickets = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const fetchedOrders = await getOrders();
      const completedOrders = fetchedOrders.filter(order => order.status === 'Completed');
      setOrders(completedOrders);
    };

    fetchOrders();
  }, []);

  return (
    <div>
      <Header />
      <div className="tickets-container">
        <h1>Tickets</h1>
        {orders.length > 0 ? (
          <div className="tickets-list">
            {orders.map((order) => (
              <div key={order.orderNumber} className="ticket-item">
                <h3>{order.productName}</h3>
                <p>Email: {order.email}</p>
                <p>Password: {order.password}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>No tickets found.</p>
        )}
      </div>
    </div>
  );
};

export default Tickets;
