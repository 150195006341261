// src/components/withAuth.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext/authProvider';

const withAuth = (Component) => {
  return function AuthenticatedComponent(props) {
    const { userLoggedIn } = useAuth();

    if (!userLoggedIn) {
      return <Navigate to="/login" />;
    }

    return <Component {...props} />;
  };
};

export default withAuth;
