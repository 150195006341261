import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Plans.css';

const plans = [
  { id: 1, name: 'Monthly Plan', price: 11, description: 'Monthly subscription plan.' },
  { id: 2, name: 'Quarterly Plan', price: 79, description: 'Quarterly subscription plan.' },
  { id: 3, name: 'Yearly Plan', price: 299, description: 'Yearly subscription plan.' },
];

const Plans = () => {
  const navigate = useNavigate();

  const handleSelectPlan = (plan) => {
    navigate('/payment', { state: { plan } });
  };

  return (
    <div className="plans-container">
      <h1>Select a Plan</h1>
      <div className="plans-list">
        {plans.map((plan) => (
          <div key={plan.id} className="plan-item">
            <h3>{plan.name}</h3>
            <p>{plan.description}</p>
            <p>Price: ${plan.price}</p>
            <button onClick={() => handleSelectPlan(plan)}>Select</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
