import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import '../styles/ProductDetails.css';
import withAuth from '../components/withAuth';

// Import product logos
import netflixLogo from '../styles/logos/netflix.png';
import chatgptplusLogo from '../styles/logos/chatgptplus.png';
import midjourneyLogo from '../styles/logos/Midjourney.png';
import geminiLogo from '../styles/logos/gemini.png';
import spotifyLogo from '../styles/logos/Spotify.png';
import disneyLogo from '../styles/logos/disneyplus.png';
import claudeLogo from '../styles/logos/claude.png';
import youtubeLogo from '../styles/logos/Youtube.png';
import canvaLogo from '../styles/logos/canva.png';
import perplexityaiLogo from '../styles/logos/perplexityai.png';

const productLogos = {
  Netflix: netflixLogo,
  'ChatGPT Plus': chatgptplusLogo,
  Midjourney: midjourneyLogo,
  Gemini: geminiLogo,
  Spotify: spotifyLogo,
  Disney: disneyLogo,
  Claude: claudeLogo,
  YouTube: youtubeLogo,
  Canva: canvaLogo,
  'Perplexity AI': perplexityaiLogo,
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  alert('Copied to clipboard!');
};

const ProductDetails = () => {
  const location = useLocation();
  const { product } = location.state || {};
  const [selectedPrice, setSelectedPrice] = useState(product?.price?.monthly || product?.price?.familyYearly || product?.price?.yearly);
  const paypalRef = useRef();
  const paypalButtonRef = useRef(null);

  useEffect(() => {
    const loadPayPalScript = () => {
      return new Promise((resolve, reject) => {
        if (window.paypal) {
          resolve(window.paypal);
          return;
        }
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&currency=USD`;
        script.async = true;
        script.onload = () => resolve(window.paypal);
        script.onerror = () => reject(new Error('Failed to load PayPal SDK'));
        document.body.appendChild(script);
      });
    };

    const initializePayPalButton = async () => {
      try {
        const paypal = await loadPayPalScript();
        if (!paypalButtonRef.current && paypalRef.current) {
          paypalButtonRef.current = paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: selectedPrice.toString(),
                    currency_code: 'USD'
                  }
                }]
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then(details => {
                console.log('Payment successful!', details);
                // Handle successful payment here (e.g., update UI, send to server)
              });
            },
            onError: (err) => {
              console.error('PayPal payment error', err);
              // Implement user-friendly error handling here
            }
          });
          await paypalButtonRef.current.render(paypalRef.current);
        } else if (paypalButtonRef.current) {
          // Update the amount without re-rendering the button
          paypalButtonRef.current.updateProps({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: selectedPrice.toString(),
                    currency_code: 'USD'
                  }
                }]
              });
            }
          });
        }
      } catch (error) {
        console.error('Failed to initialize PayPal:', error);
        // Implement fallback or error messaging for users
      }
    };

    initializePayPalButton();

    return () => {
      if (paypalButtonRef.current && paypalButtonRef.current.close) {
        paypalButtonRef.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (paypalButtonRef.current) {
      paypalButtonRef.current.updateProps({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: selectedPrice.toString(),
                currency_code: 'USD'
              }
            }]
          });
        }
      });
    }
  }, [selectedPrice]);

  if (!product) {
    return <div>No product selected</div>;
  }

  const handlePriceSelection = (price) => {
    setSelectedPrice(price);
  };

  return (
    <div>
      <Header />
      <div className="product-details-container">
        <h2 className="product-name">{product.name}</h2>
        <div className="payment-options">
          {Object.entries(product.price).map(([period, price]) => (
            <div
              key={period}
              className={`payment-option ${selectedPrice === price ? 'selected' : ''}`}
              onClick={() => handlePriceSelection(price)}
            >
              <span>{period.charAt(0).toUpperCase() + period.slice(1)}</span>
              <span>${price}</span>
            </div>
          ))}
        </div>
        <div className="final-price">${selectedPrice}</div>
        <div className="payment-buttons">
          <div ref={paypalRef}></div>
        </div>
        <div className="crypto-wallets">
          <p className="highlighted-text">
            If you encounter issues with PayPal, use these wallets and contact us on
            <a href="https://discord.gg/pJjkBRPpCX" target="_blank" rel="noopener noreferrer"> Discord </a>
            or <a href="mailto:imai@shareai.ai"> email </a> for confirmation and details:
          </p>
          <p><span>MetaMask:</span> 0x8666Fa390153348423D9dF2C9a46B5c3Ee36668D <span className="copy-icon" onClick={() => copyToClipboard('0x8666Fa390153348423D9dF2C9a46B5c3Ee36668D')}>&#128203;</span></p>
          <p><span>BNB User ID:</span> 372855185 <span className="copy-icon" onClick={() => copyToClipboard('372855185')}>&#128203;</span></p>
        </div>
      </div>
      <div className="product-logo-container">
        <img src={productLogos[product.name]} alt={`${product.name} logo`} className="product-logo" />
      </div>
    </div>
  );
};

export default withAuth(ProductDetails);