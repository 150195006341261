import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ProductDetails from './pages/ProductDetails';
import Login from './components/auth/login';
import { AuthProvider } from './contexts/authContext/authProvider';
import MyOrders from './pages/MyOrders';
import Tickets from './pages/Tickets';
import Plans from './pages/Plans';
import Payment from './components/Payment';
import About from './pages/About';

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/about" element={<About />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/payment" element={<Payment />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
