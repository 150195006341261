import React, { useEffect, useState } from 'react';
import { getOrders } from '../services/orderService'; // Ensure this path is correct
import Header from '../components/Header';
import withAuth from '../components/withAuth'; // Import the HOC

import '../styles/MyOrders.css';


const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const fetchedOrders = await getOrders();
      setOrders(fetchedOrders);
    };

    fetchOrders();
  }, []);

  const handleViewTicket = (order) => {
    setSelectedTicket(order);
  };

  const handleCloseTicket = () => {
    setSelectedTicket(null);
  };

  return (
    <div>
      <Header />
      <div className="orders-container">
        <h1>My Orders</h1>
        {orders.length > 0 ? (
          <div className="orders-list">
            {orders.map((order) => (
              <div key={order.orderNumber} className="order-item">
                <img src={order.imageUrl} alt={order.productName} className="order-image" />
                <div className="order-details">
                  <h3>{order.productName}</h3>
                  <p>Order Number: {order.orderNumber}</p>
                  <p>Purchase Date: {order.purchaseDate}</p>
                  <p>Expiration Date: {order.expirationDate}</p>
                  <p>Price: {order.price}</p>
                  <p>Status: {order.status}</p>
                  <p>Email: {order.email}</p>
                  <p>Password: {order.password}</p>
                  {order.status === 'Completed' && (
                    <button
                      className="view-ticket-button"
                      onClick={() => handleViewTicket(order)}
                    >
                      View Ticket
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No orders found.</p>
        )}
        {selectedTicket && (
          <div className="ticket-modal">
            <div className="ticket-content">
              <h2>Ticket Details</h2>
              <p>Email: {selectedTicket.email}</p>
              <p>Password: {selectedTicket.password}</p>
              <button onClick={handleCloseTicket}>Close</button>
            </div>
          </div>
        )}
      </div>
      <div className="footer-text">
      Order details will be sent to your purchase email. If you can't access it, contact us on  <a href="https://discord.gg/pJjkBRPpCX" target="_blank" rel="noopener noreferrer"> Discord </a> or via <a href="mailto:imai@shareai.ai">Email</a> to email.
      </div>
    </div>
  );
};

export default withAuth(MyOrders); // Wrap with HOC
