import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/Payment.css';

const Payment = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const paypalRef = useRef();

  useEffect(() => {
    if (window.paypal && state && state.plan) {
      window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'paypal',
        },
        createOrder: async (data, actions) => {
          try {
            const response = await fetch('/api/orders', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                cart: [
                  {
                    id: "YOUR_PRODUCT_ID",
                    quantity: 1,
                  },
                ],
              }),
            });

            const orderData = await response.json();

            if (orderData.id) {
              return orderData.id;
            } else {
              const errorDetail = orderData?.details?.[0];
              const errorMessage = errorDetail
                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                : JSON.stringify(orderData);

              throw new Error(errorMessage);
            }
          } catch (error) {
            console.error(error);
          }
        },
        onApprove: async (data, actions) => {
          try {
            const response = await fetch(`/api/orders/${data.orderID}/capture`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            });

            const orderData = await response.json();

            const errorDetail = orderData?.details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
              return actions.restart();
            } else if (errorDetail) {
              throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
            } else if (!orderData.purchase_units) {
              throw new Error(JSON.stringify(orderData));
            } else {
              const transaction = orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
              alert(`Transaction ${transaction.status}: ${transaction.id}`);
              navigate('/tickets');
            }
          } catch (error) {
            console.error(error);
          }
        },
        onError: (err) => {
          console.error('PayPal payment error:', err);
        }
      }).render(paypalRef.current);
    }
  }, [state, navigate]);

  return (
    <div className="payment-container">
      <h1>Complete Payment</h1>
      {state && state.plan ? (
        <>
          <h3>Plan: {state.plan.name}</h3>
          <p>Price: ${state.plan.price}</p>
          <div ref={paypalRef} id="paypal-button-container"></div>
        </>
      ) : (
        <p>No plan selected.</p>
      )}
    </div>
  );
};

export default Payment;
