import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { signInWithGoogle, sendVerificationCode, verifyCodeAndSignIn } from '../../firebase/auth';
import { useAuth } from '../../contexts/authContext/authProvider';
import '../../styles/Login.css';

const Login = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationId, setVerificationId] = useState('');

  const handleSendCode = async (e) => {
    e.preventDefault();
    if (!isCodeSent) {
      setIsCodeSent(true);
      try {
        const formattedPhoneNumber = `+${phoneNumber.replace(/\D/g, '')}`;
        const verificationId = await sendVerificationCode(formattedPhoneNumber);
        setVerificationId(verificationId);
      } catch (error) {
        setErrorMessage(error.message);
        setIsCodeSent(false);
      }
    }
  };

  const handlePhoneSignIn = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        await verifyCodeAndSignIn(verificationId, verificationCode);
        navigate('/home');
      } catch (error) {
        setErrorMessage(error.message);
        setIsSigningIn(false);
      }
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        await signInWithGoogle();
        navigate('/home');
      } catch (error) {
        setErrorMessage(error.message);
        setIsSigningIn(false);
      }
    }
  };

  return (
    <div>
      {userLoggedIn && <Navigate to='/home' replace={true} />}
      <div className="login-overlay">
        <main className='login-container'>
          <img src="../styles/images/ShareAI.png" alt="ShareAI" className="login-image" />
          <div className='login-box'>
            <div className='text-center mb-6'>
              <h3 className='text-gray-800 text-xl font-semibold sm:text-2xl'>Login/SignUp</h3>
            </div>
            <form onSubmit={handlePhoneSignIn} className='login-form'>
              <div>
                <label className='text-sm text-gray-600 font-bold'>Phone Number</label>
                <PhoneInput
                  country={'us'}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  inputClass='login-input'
                />
              </div>
              <div>
                <label className='text-sm text-gray-600 font-bold'>Verification Code</label>
                <input
                  type='text'
                  required
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className='login-input'
                />
                <button onClick={handleSendCode} className='send-code-button' disabled={isCodeSent}>
                  {isCodeSent ? 'Code Sent' : 'Send Code'}
                </button>
              </div>
              {errorMessage && <span className='text-red-600 font-bold'>{errorMessage}</span>}
              <button
                type='submit'
                disabled={isSigningIn}
                className={`login-button ${isSigningIn ? 'disabled' : ''}`}
              >
                {isSigningIn ? 'Signing In...' : 'Login/SignUp'}
              </button>
            </form>
            <div className='separator'>
              <div className='line'></div>
              <div className='text'>OR</div>
              <div className='line'></div>
            </div>
            <button
              onClick={handleGoogleSignIn}
              className={`google-button ${isSigningIn ? 'disabled' : ''}`}
            >
              <svg className='google-icon' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0)'>
                  <path
                    d='M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z'
                    fill='#4285F4'
                  />
                  <path
                    d='M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z'
                    fill='#34A853'
                  />
                  <path
                    d='M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z'
                    fill='#FBBC04'
                  />
                  <path
                    d='M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z'
                    fill='#EA4335'
                  />
                </g>
                <defs>
                  <clipPath id='clip0'>
                    <rect width='48' height='48' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              {isSigningIn ? 'Signing In...' : 'Continue with Google'}
            </button>
            <div id="recaptcha-container"></div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Login;
