// /services/orderService.js
export const getOrders = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found in localStorage');
    }

    const response = await fetch('/api/orders', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }

    const orders = await response.json();
    return orders.map(order => ({
      productName: order.productName,
      orderNumber: order.orderNumber,
      purchaseDate: new Date(order.purchaseDate).toLocaleString(),
      expirationDate: new Date(order.expirationDate).toLocaleString(),
      price: order.price,
      status: order.status,
      email: order.email,
      password: order.password,
      imageUrl: order.imageUrl || '/default-image.png', // Default image if none is provided
    }));
  } catch (error) {
    console.error('Error fetching orders:', error);
    return [];
  }
};
